import { useParams } from "react-router-dom";

import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react";
import { t } from "i18next";

import { useOrganizationByIdQuery } from "../infrastructure/useOrganizationByIdQuery";

export const DemoWatermark = () => {
  const { organizationId } = useParams<{ organizationId: string }>();

  if (!organizationId) {
    return null;
  }

  return <DemoWatermarkConnected organizationId={organizationId} />;
};

interface IProps {
  organizationId: string;
}

const DemoWatermarkConnected = ({ organizationId }: IProps) => {
  const { data, isLoading } = useOrganizationByIdQuery(organizationId);

  if (isLoading) {
    return null;
  }

  if (!data?.organization.configuration.demoAccess) {
    return null;
  }

  return (
    <Alert
      status="info"
      left={8}
      top={0}
      mb={2}
      position="sticky"
      maxW="calc(100vw - 78px)"
      zIndex="2"
      borderRadius="md"
      boxShadow="md"
      id="demo-watermark"
    >
      <AlertIcon />
      <AlertDescription>
        {t("Używasz wersji demonstracyjnej serwisu.")}
      </AlertDescription>
    </Alert>
  );
};
