import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

import { usePendingVerificationsQuery } from "../infrastructure/usePendingVerificationsQuery";
import { useUnreadVerificationToast } from "./useUnreadVerificationToast";

interface IProps {
  contextId: string;
}

const UnreadVerificationsToasterConnected = ({ contextId }: IProps) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data, isLoading } = usePendingVerificationsQuery(contextId);
  const shownNotification = useRef(false);

  const toast = useUnreadVerificationToast();

  useEffect(() => {
    if (shownNotification.current) return;

    if (
      !isLoading &&
      data?.pendingVerifications.length === 0 &&
      organizationId
    ) {
      shownNotification.current = true;
    }

    if (!isLoading && data?.pendingVerifications.length > 0 && organizationId) {
      shownNotification.current = true;
      toast(organizationId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data?.pendingVerifications.length, organizationId]);

  return null;
};

export const UnreadVerificationsToaster = () => {
  const context = ContextMachineContext.useSelector((state) => state.context)
    .userInfo?.currentlySelectedContext;

  if (!context || context.type !== "context_user") {
    return null;
  }

  return <UnreadVerificationsToasterConnected contextId={context.id} />;
};
