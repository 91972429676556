import { ComponentProps } from "react";

import { mdiLinkOff } from "@mdi/js";

import { Icon } from "./Icon";

export const LinkOffIcon = (
  props: Omit<ComponentProps<typeof Icon>, "path">
) => {
  return <Icon {...props} path={mdiLinkOff} />;
};
