import { withTranslation } from "react-i18next";

import { SimpleGrid, VStack } from "@chakra-ui/react";

import { BlackListTile } from "modules/dashboard/presentation/BlackListTile";
import { ChangelogTile } from "modules/dashboard/presentation/ChangelogTile";
import { ContextManagersTile } from "modules/dashboard/presentation/ContextManagersTile";
import { ExpiringTariffsTile } from "modules/dashboard/presentation/ExpiringTariffsTile";
import { MenageNotificationTile } from "modules/dashboard/presentation/MenageNotificationTile";
import { MenageOrganizationsTile } from "modules/dashboard/presentation/MenageOrganizationsTile";
import { TariffsTiles } from "modules/dashboard/presentation/TariffsTiles";

export const ManagerDashboard = withTranslation()(() => {
  return (
    <VStack align="stretch" gap="8" maxW="1400px" mx="auto">
      <SimpleGrid gap={6} columns={{ base: 2, lg: 4 }}>
        <TariffsTiles />
        <MenageOrganizationsTile />
        <ChangelogTile />
        <ContextManagersTile />
        <ExpiringTariffsTile />
        <MenageNotificationTile />
        <BlackListTile />
      </SimpleGrid>
    </VStack>
  );
});
