import { useParams } from "react-router-dom";

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Heading,
  HStack,
  Skeleton,
  Spacer,
  Stack,
  Text,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import { t } from "i18next";

import { formatNumber } from "utils/i18n/formatNumber";
import { relativeTime } from "utils/i18n/relativeTime";

import { ActiveBadge } from "components/ActiveBadge";
import { CircularProgress } from "components/CircularProgress";
import { FormatDate } from "components/FormatDate";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { RocketIcon } from "components/icons/RocketIcon";

import { hasPPDOAvailable } from "modules/tariffs/application/hasPPDOAvailable";
import { ITariff } from "modules/tariffs/application/types/ITariff";
import { useAllTariffsQuery } from "modules/tariffs/infrastructure/useAllTariffsQuery";
import { useTariffsExpirationsQuery } from "modules/tariffs/infrastructure/useTariffsExpirationsQuery";

import { AddTariffButton } from "../AddTariffButton";
import { EditTariffButton } from "../common/EditTariffButton/EditTariffButton";
import { ExpirationAlert } from "../common/ExpirationAlert";
import { ToggleTariffButton } from "../common/ToggleTariffButton";
import { TrueFalseIcons } from "../common/TrueFalseIcons";

interface IProps {
  isLoading: boolean;
  tariff: ITariff | null;
}

const CurrentTariffConnected = ({ isLoading, tariff }: IProps) => {
  if (isLoading) {
    return (
      <Stack>
        <Skeleton height="78px" />
      </Stack>
    );
  }

  if (tariff === null) {
    return <TableEmptyState title={t("Brak pakietu")} />;
  }

  const totalCount =
    tariff.usageLimit.apiVerificationCount +
    tariff.usageLimit.defaultVerificationCount;

  const percentageUsage = (totalCount / tariff.usageLimit.totalLimit!) * 100;

  const isUnlimited = tariff.usageLimit.type === "UNLIMITED";

  return (
    <HStack spacing={4} alignItems="flex-start">
      {isUnlimited ? (
        <RocketIcon h="78px" w="78px" p={3} color="purple.500" />
      ) : (
        <CircularProgress percentage={percentageUsage} size="78px" pt={2} />
      )}
      <VStack spacing="0" align="stretch" w="full" fontSize="sm">
        <Accordion allowToggle>
          <AccordionItem border="none">
            {tariff.usageStats.calculated ? (
              <AccordionButton role="group" _hover={{}} pl={0}>
                <HStack>
                  <Text
                    _groupHover={{ textDecoration: "underline" }}
                    fontWeight="600"
                    fontSize="lg"
                  >
                    {t("Pakiet {{size}}", { size: tariff.type })}
                  </Text>
                  <ActiveBadge isActive={tariff.isActive} />
                </HStack>
                <Spacer />
                <AccordionIcon />
              </AccordionButton>
            ) : (
              <HStack>
                <Text
                  _groupHover={{ textDecoration: "underline" }}
                  fontWeight="600"
                  fontSize="lg"
                >
                  {t("Pakiet {{size}}", { size: tariff.type })}
                </Text>
                <ActiveBadge isActive={tariff.isActive} />
              </HStack>
            )}
            {isUnlimited ? (
              <Text textColor="gray.600">
                {t("Weryfikacje wykonane w ramach pakietu: {{totalCount}}", {
                  totalCount: formatNumber(totalCount),
                })}
              </Text>
            ) : (
              <Text textColor="gray.600">
                {t(
                  "Wykonano {{totalCount}} z {{limit}} dostępnych weryfikacji",
                  {
                    totalCount: formatNumber(totalCount),
                    limit: formatNumber(tariff.usageLimit.totalLimit!),
                  }
                )}
              </Text>
            )}
            <HStack data-testid="api-access">
              <Text textColor="gray.600">{t("Dostęp do API")}</Text>
              <TrueFalseIcons isSelected={tariff.apiAccess} />
            </HStack>
            {hasPPDOAvailable(tariff.type) && (
              <HStack data-testid="ppdo">
                <Text textColor="gray.600">{t("PPDO")}</Text>
                <TrueFalseIcons isSelected={tariff.personalDataClause} />
              </HStack>
            )}
            <Text textColor="gray.600">
              {t("Ważny od")} <FormatDate date={tariff.validFrom} /> {t("do")}{" "}
              <FormatDate date={tariff.validTo} />{" "}
              {relativeTime(tariff.validTo)}
            </Text>
            <AccordionPanel pb={4} px={0}>
              <Divider mb={2} />
              <HStack
                spacing={4}
                fontWeight={400}
                color="gray.600"
                fontSize="sm"
                wrap="wrap"
              >
                <Text>
                  {t("Zweryfikowane pliki: {{count}}", {
                    count: tariff.usageStats.filesCount,
                  })}
                </Text>
                <Text>
                  {t("Zweryfikowane podpisy: {{count}}", {
                    count: tariff.usageStats.signaturesCount,
                  })}
                </Text>
              </HStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </VStack>
    </HStack>
  );
};

export const CurrentTariff = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data, isLoading } = useAllTariffsQuery(organizationId!);
  const { data: expirations } = useTariffsExpirationsQuery(organizationId!);

  return (
    <Card id="current-tariff" variant="sawpe">
      <CardHeader>
        <Heading fontSize="lg">{t("Aktualny pakiet")}</Heading>
        <Wrap spacing={3}>
          <AddTariffButton />
          <EditTariffButton tariff={data?.tariffs.current} />
          <ToggleTariffButton tariff={data?.tariffs.current} />
        </Wrap>
      </CardHeader>
      <CardBody>
        <VStack align="stretch" spacing={4}>
          <ExpirationAlert
            expirations={expirations?.tariffsReminders.filter(
              ({ tariffId }) => tariffId === data?.tariffs.current?.id
            )}
            nextTariff={data?.tariffs.next}
          />
          <CurrentTariffConnected
            isLoading={isLoading}
            tariff={data?.tariffs.current}
          />
        </VStack>
      </CardBody>
    </Card>
  );
};
