import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Td,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { relativeTime } from "utils/i18n/relativeTime";

import { SearchInput } from "components/Filters/SearchInput";
import { FormatDate } from "components/FormatDate";
import { Table } from "components/Table";
import { LinkCell } from "components/Table/LinkCell";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { TableLoadingState } from "components/Table/TableLoadingState";
import { PlusIcon } from "components/icons/PlusIcon";

import { canToggleOrganization } from "modules/organization/application/canToggleOrganization";
import {
  IOrganization,
  OrganizationStatus,
} from "modules/organization/application/types/IOrganization";
import { useOrganizationsQuery } from "modules/organization/infrastructure/useOrganizationsQuery";
import { TariffTypes } from "modules/tariffs/application/types/ITariff";

import {
  OrganizationModal,
  useOpenOrganizationalModal,
} from "../OrganizationModal";
import { OrganizationStatusBadge } from "../OrganizationStatusBadge";
import { DemoStatus } from "./DemoStatus";
import { EditableComment } from "./EditableComment";
import { IntegrationStatus } from "./IntegrationStatus";
import { OrganizationFiltersDrawer } from "./OrganizationFiltersDrawer";
import { ToggleOrganizationButton } from "./ToggleOrganizationButton";
import { UsageLimit } from "./UsageLimit";

const columnHelper = createColumnHelper<IOrganization>();

const columns = [
  columnHelper.accessor("details.displayName", {
    id: "configuration",
    header: () => t("Nazwa"),
    filterFn: (row, _, filterValue) => {
      if (!filterValue.length) return true;

      return row.original.configuration.demoAccess;
    },
    cell: (info) => (
      <Td data-testid="organization-name" whiteSpace="nowrap" w="75%" maxW={0}>
        <HStack>
          <Text isTruncated>{info.getValue()}</Text>
          <IntegrationStatus
            tariff={
              info.row.original.tariffs.current ||
              info.row.original.tariffs.next
            }
          />
          <DemoStatus configuration={info.row.original.configuration} />
        </HStack>
      </Td>
    ),
  }),
  columnHelper.accessor("details.email", {
    id: "email",
    header: () => t("E-mail"),
    cell: (info) => (
      <Td whiteSpace="nowrap" w="25%" maxW={0}>
        <Text isTruncated>{info.getValue()}</Text>
      </Td>
    ),
  }),
  columnHelper.accessor((row) => row.tariffs?.current?.type || null, {
    id: "tariff.type",
    filterFn: (row, id, filterValue: TariffTypes[]) => {
      if (!filterValue.length) return true;

      return filterValue.includes(row.getValue(id));
    },
    header: () => t("Pakiet"),
    cell: (info) => <Td whiteSpace="normal">{info.getValue() || "---"}</Td>,
  }),
  columnHelper.accessor(
    (row) =>
      row.tariffs.current?.usageLimit
        ? row.tariffs.current?.usageLimit.defaultVerificationCount +
          row.tariffs.current?.usageLimit.apiVerificationCount
        : -1,
    {
      id: "tariff.usageLimit",
      header: () => t("Wykorzystanie"),
      cell: (info) => {
        const usageLimit = info.row.original.tariffs.current?.usageLimit;
        if (!usageLimit) {
          return <Td whiteSpace="normal">{t("Bez pakietu")}</Td>;
        }

        return (
          <Td whiteSpace="normal">
            <UsageLimit {...usageLimit} />
          </Td>
        );
      },
    }
  ),
  columnHelper.accessor("tariffs.current.validFrom", {
    id: "tariff",
    header: () => t("Umowa"),
    cell: (info) => {
      const tariff = info.row.original.tariffs.current;
      if (!tariff) {
        return <Td>---</Td>;
      }

      return (
        <Td>
          <FormatDate date={tariff.validFrom} /> -{" "}
          <FormatDate date={tariff.validTo} />
          <Text textColor="gray.600">{relativeTime(tariff.validTo)}</Text>
        </Td>
      );
    },
  }),
  columnHelper.accessor("status", {
    id: "status",
    header: () => t("Status"),
    filterFn: (row, id, filterValue: OrganizationStatus[]) => {
      if (!filterValue.length) return true;

      return filterValue.includes(row.getValue(id));
    },
    cell: (info) => (
      <Td whiteSpace="normal" data-testid="organization-active-badge">
        <OrganizationStatusBadge status={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("comment", {
    id: "comment",
    header: () => t("Komentarz"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <EditableComment
          value={info.getValue() || ""}
          organizationId={info.row.original.id}
        />
      </Td>
    ),
  }),
  columnHelper.accessor("id", {
    header: () => "",
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal" isNumeric>
        <ButtonGroup>
          <ToggleOrganizationButton
            isDisabled={
              !canToggleOrganization(
                info.row.original.tariffs,
                info.row.original.status
              )
            }
            isActive={info.row.original.status === "ACTIVE"}
            organizationId={info.getValue()}
            organizationName={info.row.original.details.displayName}
          />
          <LinkCell path={`/${info.row.original.id}`} label={t("Przejdź")} />
        </ButtonGroup>
      </Td>
    ),
  }),
];

interface IProps {
  organizations: IOrganization[];
  isLoading: boolean;
}

const OrganizationsTableConnected = ({ organizations, isLoading }: IProps) => {
  const isVisibleOnLg = useBreakpointValue(
    { base: false, lg: true },
    { fallback: "lg", ssr: false }
  )!;
  const isVisibleOnMd = useBreakpointValue(
    { base: false, md: true },
    { fallback: "md", ssr: false }
  )!;
  const isVisibleOnXl = useBreakpointValue(
    { base: false, md: false, xl: true },
    { fallback: "xl", ssr: false }
  )!;
  const isVisibleOn2Xl = useBreakpointValue(
    { base: false, md: false, "2xl": true },
    { fallback: "2xl", ssr: false }
  )!;

  if (isLoading) {
    return <TableLoadingState />;
  }

  if (organizations?.length === 0) {
    return <TableEmptyState title={t("Lista podmiotów jest pusta")} />;
  }

  const sortedRecords = reverse(
    sortBy(organizations, function (data) {
      return new Date(data.createdAt);
    })
  );

  return (
    <Table<IOrganization>
      data={sortedRecords}
      columns={columns}
      columnVisibility={{
        email: isVisibleOn2Xl,
        comment: isVisibleOn2Xl,
        "tariff.type": isVisibleOnLg,
        "tariff.usageLimit": isVisibleOnXl,
        tariff: isVisibleOnXl,
        status: isVisibleOnMd,
      }}
    />
  );
};

export const OrganizationsTable = () => {
  const { data, isLoading } = useOrganizationsQuery();
  const onOpen = useOpenOrganizationalModal();

  return (
    <>
      <OrganizationModal />
      <Card variant="sawpe">
        <CardHeader justifyContent="space-between">
          <HStack spacing={4} wrap="wrap">
            <Heading fontSize="lg">{t("Lista podmiotów")}</Heading>
            <Button
              colorScheme="purple"
              size="sm"
              leftIcon={<PlusIcon />}
              onClick={() => onOpen()}
              flexShrink={0}
            >
              {t("Dodaj podmiot")}
            </Button>
          </HStack>
          <HStack
            wrap={{ base: "wrap", md: "nowrap" }}
            justifyContent="flex-end"
          >
            <SearchInput
              placeholder={t("Nazwa lub email")}
              data-testid="organization-search-input"
            />
            <OrganizationFiltersDrawer />
          </HStack>
        </CardHeader>
        <CardBody>
          <OrganizationsTableConnected
            organizations={data?.organizations}
            isLoading={isLoading}
          />
        </CardBody>
      </Card>
    </>
  );
};
