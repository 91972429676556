import { Tag } from "@chakra-ui/react";
import { t } from "i18next";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

import { OrganizationStatus } from "../application/types/IOrganization";

interface IProps {
  status: OrganizationStatus;
}

export const OrganizationStatusBadge = ({ status }: IProps) => {
  const context = ContextMachineContext.useSelector((state) => state.context)
    .userInfo?.currentlySelectedContext?.type;

  if (context !== "system_manager") {
    return null;
  }

  switch (status) {
    case "NEW":
      return (
        <Tag
          size="sm"
          variant="subtle"
          colorScheme="blue"
          verticalAlign="middle"
        >
          {t("Nowy")}
        </Tag>
      );
    case "ACTIVE":
      return (
        <Tag
          size="sm"
          variant="subtle"
          colorScheme="green"
          verticalAlign="middle"
        >
          {t("Aktywny")}
        </Tag>
      );
    case "BLOCKED":
      return (
        <Tag
          size="sm"
          variant="subtle"
          colorScheme="orange"
          verticalAlign="middle"
        >
          {t("Nieaktywny")}
        </Tag>
      );
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-case-declarations
      const newStatus: never = status;
      return null;
  }
};
