import {
  IOrganization,
  OrganizationStatus,
} from "modules/organization/application/types/IOrganization";

export const getOrganizationsStatistics = (organizations: IOrganization[]) => {
  const organizationStatistics: Record<OrganizationStatus, number> = {
    ACTIVE: 0,
    BLOCKED: 0,
    NEW: 0,
  };

  return organizations.reduce((stats, organization) => {
    stats[organization.status]++;
    return stats;
  }, organizationStatistics);
};
