import { GridItem, SimpleGrid, Skeleton } from "@chakra-ui/react";

import { countTariffs } from "modules/dashboard/application/countTariffs";
import { useOrganizationsQuery } from "modules/organization/infrastructure/useOrganizationsQuery";
import { TariffTypes } from "modules/tariffs/application/types/ITariff";

import { TariffTile } from "./TariffTile";

export const TariffsTiles = () => {
  const { data, isLoading } = useOrganizationsQuery();

  if (isLoading) {
    return (
      <GridItem colSpan={{ base: 2, lg: 4 }}>
        <SimpleGrid gap={6} columns={{ base: 2, lg: 4 }}>
          <GridItem>
            <Skeleton height="150px" />
          </GridItem>
          <GridItem>
            <Skeleton height="150px" />
          </GridItem>
          <GridItem>
            <Skeleton height="150px" />
          </GridItem>
          <GridItem>
            <Skeleton height="150px" />
          </GridItem>
        </SimpleGrid>
      </GridItem>
    );
  }

  const countedTariffs = countTariffs(data?.organizations);
  const total = Object.values(countedTariffs).reduce(
    (sum, value) => sum + value,
    0
  );

  return (
    <GridItem colSpan={{ base: 2, lg: 4 }}>
      <SimpleGrid gap={6} columns={{ base: 2, lg: 4 }}>
        {Object.entries(countedTariffs).map(([tariffType, count]) => {
          return (
            <TariffTile
              key={tariffType}
              total={total}
              tariffType={tariffType as TariffTypes}
              activeOrganizations={count}
            />
          );
        })}
      </SimpleGrid>
    </GridItem>
  );
};
