import { useParams } from "react-router-dom";

import {
  Box,
  Card,
  CardBody,
  Heading,
  HStack,
  Skeleton,
  Spacer,
  Stack,
} from "@chakra-ui/react";

import { canToggleOrganization } from "modules/organization/application/canToggleOrganization";
import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";

import { OrganizationStatusBadge } from "../OrganizationStatusBadge";
import { BlackListBtn } from "./BlackListBtn";
import { OrganizationConfigurationBtn } from "./OrganizationConfigurationBtn";
import { ToggleOrganizationBtn } from "./ToggleOrganizationBtn";

export const OrganizationHeader = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data, isLoading } = useOrganizationByIdQuery(organizationId!);

  if (isLoading) {
    return (
      <Card>
        <CardBody px={4} py={6}>
          <Skeleton height="28px" />
        </CardBody>
      </Card>
    );
  }

  const nameLength = data?.organization.details.displayName.length;

  return (
    <Card>
      <CardBody px={4} py={6}>
        <HStack wrap="wrap">
          <Box>
            <Heading
              fontSize={
                nameLength >= 150
                  ? { base: "sm", md: "md" }
                  : { base: "lg", md: "2xl" }
              }
              display="inline"
              verticalAlign="middle"
              mr={2}
              data-testid="organization-name"
            >
              {data.organization.details.displayName}
            </Heading>
            <OrganizationStatusBadge status={data.organization.status} />
          </Box>
          <Spacer />
          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent={{ base: "start", md: "end" }}
          >
            <ToggleOrganizationBtn
              isDisabled={
                !canToggleOrganization(
                  data.organization.tariffs,
                  data.organization.status
                )
              }
              organizationId={data.organization.id}
              isActive={data.organization.status === "ACTIVE"}
              organizationName={data.organization.details.displayName}
            />
            <BlackListBtn isOnBlackList={!!data.organization.blackList} />
            <OrganizationConfigurationBtn />
          </Stack>
        </HStack>
      </CardBody>
    </Card>
  );
};
