import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";

import { ChevronRightIcon } from "components/icons/ChevronRightIcon";
import { GhostIcon } from "components/icons/GhostIcon";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

import { ChangeContextButton } from "./ChangeContextButton";
import { ChangePasswordMenuItem } from "./ChangePasswordMenuItem";
import { ExitImpersonateMenuItem } from "./ExitImpersonateMenuItem";

export const ProfileMenu = () => {
  const { userInfo } = ContextMachineContext.useSelector(
    (state) => state.context
  );
  const fullName = userInfo?.currentlySelectedContext?.name;

  const impersonate = !!sessionStorage.getItem("impersonateToken");

  return (
    <Menu>
      <MenuButton
        as={Button}
        title={t("Menu użytkownika")}
        leftIcon={
          <Avatar
            name={impersonate ? undefined : fullName}
            size="sm"
            icon={impersonate ? <GhostIcon /> : undefined}
            mr={{ lg: -4, xl: 0 }}
          />
        }
        rightIcon={
          <ChevronRightIcon
            size="16px"
            color="gray.400"
            transform={"rotate(90deg)"}
            display={{ base: "block", lg: "none", xl: "block" }}
          />
        }
        data-testid="profile-menu-button"
        variant="ghost"
        fontWeight={500}
      >
        <Box
          isTruncated
          maxW="160px"
          display={{ base: "block", lg: "none", xl: "block" }}
        >
          {fullName}
        </Box>
        {impersonate && (
          <Text
            display={{ base: "block", lg: "none", xl: "block" }}
            fontSize="sm"
            color="gray.400"
            textAlign="left"
          >
            {t("Impersonate")}
          </Text>
        )}
      </MenuButton>
      <MenuList>
        {impersonate && <ExitImpersonateMenuItem />}
        <ChangeContextButton />
        <ChangePasswordMenuItem />
        <LogoutMenuItem />
      </MenuList>
    </Menu>
  );
};

const LogoutMenuItem = () => {
  const { send } = ContextMachineContext.useActorRef();

  return (
    <MenuItem
      onClick={() => {
        send({ type: "logout" });
      }}
    >
      {t("Wyloguj")}
    </MenuItem>
  );
};
