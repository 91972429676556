import { QueryClient } from "@tanstack/react-query";
import { HttpResponse, http } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { IAllTariffs } from "../application/types/IAllTariffs";

const query = (organizationId: string) => {
  return {
    queryKey: ["organizations", organizationId, "tariffs"],
    queryFn: () =>
      client.get<{ tariffs: IAllTariffs }>(
        `organizations/${organizationId}/tariffs`
      ),
  };
};
export const getTariffsQuery =
  (queryClient: QueryClient) => (organizationId: string) => {
    return queryClient.ensureQueryData(query(organizationId));
  };

/**
 * @description return only active tariffs
 */
export const useTariffsQuery = (organizationId: string) => {
  return useQuery(query(organizationId));
};

export const getTariffsMock = ({ current, next }: Partial<IAllTariffs>) => {
  return http.get(withHost("organizations/:organizationId/tariffs"), () => {
    return HttpResponse.json<{ tariffs: IAllTariffs }>({
      tariffs: {
        current: current || null,
        next: next || null,
      },
    });
  });
};
