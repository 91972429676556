import { Box, IconButton, Tag, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { SignatureIcon } from "components/icons/SignatureIcon";

import { IVerificationStats } from "modules/verification/application/types/IVerificationHistoryRecord";

interface IProps {
  stats: IVerificationStats;
}

export const SignaturesCountBtn = ({ stats }: IProps) => {
  return (
    <Tooltip label={t("Zweryfikowane podpisy")}>
      <Box position="relative">
        <Tag
          size="sm"
          variant="subtle"
          color="purple.800"
          position="absolute"
          data-testid="verified-signatures-count"
          zIndex={2}
          top={-2}
          right={-2}
        >
          {stats.signaturesCount}
        </Tag>
        <IconButton
          variant="link"
          colorScheme="purple"
          aria-label={t("Zweryfikowane podpisy")}
          icon={<SignatureIcon size="24px" />}
          pointerEvents="none"
        />
      </Box>
    </Tooltip>
  );
};
