import { useEffect } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";
import { useEventSourceConsumer } from "modules/events/application/EventSourceProvider";

import { useGetPendingVerificationsQuery } from "./usePendingVerificationsQuery";

export const useListenForPendingVerifications = (toast: () => void) => {
  const contextId = ContextMachineContext.useSelector((state) => state.context)
    .userInfo?.currentlySelectedContext?.id;
  const queryClient = useQueryClient();

  const { subscribe, unsubscribe } = useEventSourceConsumer();
  const { mutateAsync: getPendingVerifications } =
    useGetPendingVerificationsQuery(contextId!);

  useEffect(() => {
    subscribe({
      id: "new-unread-verification",
      eventType: "verification_process_update",
      onEvent: (event) => {
        if (event.status === "FINISHED") {
          // Czekam 3.5 sekund, żeby mieć pewność że ta weryfikacja nie została wyświetlona w innym kontekscie
          setTimeout(() => {
            getPendingVerifications().then(({ pendingVerifications }) => {
              const isPending = pendingVerifications.find(
                ({ verificationId }) => verificationId === event.verificationId
              );
              if (isPending) {
                toast();
              }
            });
          }, 3_500);
        }
      },
    });

    // Cleanup on component unmount
    return () => {
      unsubscribe("new-unread-verification");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextId, queryClient]);
};
