import { useEffect } from "react";
import { Control, useController } from "react-hook-form";

import { Box, ButtonGroup, HStack, IconButton, VStack } from "@chakra-ui/react";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import { useEditor, EditorContent, EditorContentProps } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { t } from "i18next";

import { FormControl } from "components/Form/FormControl";
import { FormatBoldIcon } from "components/icons/FormatBoldIcon";
import { FormatItalicIcon } from "components/icons/FormatItalicIcon";
import { FormatListBulletedIcon } from "components/icons/FormatListBulletedIcon";
import { FormatListNumberedIcon } from "components/icons/FormatListNumberedIcon";
import { FormatUnderlineIcon } from "components/icons/FormatUnderlineIcon";
import { LinkIcon } from "components/icons/LinkIcon";
import { LinkOffIcon } from "components/icons/LinkOffIcon";

import { InsertLinkPopover } from "./InsertLinkPopover";

interface IProps {
  control: Control;
  name: string;
  label: string;
}

export const TextEditor = ({ control, name, label }: IProps) => {
  const {
    field: { value, onChange },
    formState: { errors },
  } = useController({
    name,
    control,
    defaultValue: "",
    rules: {
      required: t("Pole jest wymagane."),
      validate: (value) => {
        if (!value || value.trim().length === 0) {
          return t("Pole jest wymagane.");
        }
      },
    },
  });

  const editor = useEditor({
    extensions: [StarterKit, Link, Underline],
    content: value, // Initialize with the current value
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML()); // Update form field on content change
    },
    editorProps: {
      attributes: {
        class: "editor-content",
      },
      handleKeyDown: (view, event) => {
        if (event.key === "Tab") {
          event.preventDefault();
          view.dispatch(
            view.state.tr.insertText("   ") // Insert a tab character
          );
          return true; // Indicate that the event was handled
        }
        return false;
      },
    },
  });

  // has to be better way to reset editor content
  useEffect(() => {
    if (editor && editor.getHTML() !== value) {
      editor.commands.setContent(value || ""); // Reset editor content
    }
  }, [editor, value]);

  return (
    <VStack align="stretch">
      <FormControl
        formLabel={label}
        isRequired
        formErrorMessage={errors[name]?.message as string}
      >
        <Toolbar editor={editor} />
        <Container editor={editor} />
      </FormControl>
    </VStack>
  );
};

// Toolbar Component
const Toolbar = ({
  editor,
}: {
  editor: ReturnType<typeof useEditor> | null;
}) => {
  if (!editor) return null;

  return (
    <HStack align="start" justify="space-between">
      <ButtonGroup
        spacing={1}
        size="sm"
        variant="ghost"
        colorScheme="gray"
        mb={2}
        flexWrap={"wrap"}
      >
        <IconButton
          onClick={() => editor.chain().focus().toggleBold().run()}
          isDisabled={!editor.can().chain().focus().toggleBold().run()}
          aria-label="Bold"
          icon={<FormatBoldIcon />}
          bg={editor.isActive("bold") ? "gray.300" : "none"}
        />
        <IconButton
          onClick={() => editor.chain().focus().toggleItalic().run()}
          isDisabled={!editor.can().chain().focus().toggleItalic().run()}
          aria-label="Italic"
          icon={<FormatItalicIcon />}
          bg={editor.isActive("italic") ? "gray.300" : "none"}
        />
        <IconButton
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          isDisabled={!editor.can().chain().focus().toggleUnderline().run()}
          aria-label="Underline"
          icon={<FormatUnderlineIcon />}
          bg={editor.isActive("underline") ? "gray.300" : "none"}
        />
        <IconButton
          aria-label="List bulleted"
          icon={<FormatListBulletedIcon />}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          bg={editor.isActive("bulletList") ? "gray.300" : "none"}
        />
        <IconButton
          aria-label="List numbered"
          icon={<FormatListNumberedIcon />}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          bg={editor.isActive("orderedList") ? "gray.300" : "none"}
        />
        <InsertLinkPopover
          onSave={(url: string) => {
            editor.chain().focus().setLink({ href: url }).run();
          }}
        >
          <IconButton
            aria-label="Add Link"
            icon={<LinkIcon />}
            disabled={!editor.can().chain().focus().setLink({ href: "" }).run()}
          />
        </InsertLinkPopover>
        <IconButton
          aria-label="Remove Link"
          icon={<LinkOffIcon />}
          onClick={() => editor.chain().focus().unsetLink().run()}
          disabled={!editor.can().chain().focus().unsetLink().run()}
        />
      </ButtonGroup>
    </HStack>
  );
};

const Container = (props: EditorContentProps) => {
  return (
    <Box
      {...props}
      as={EditorContent}
      sx={{
        ".tiptap": {
          "> * + *": {
            marginTop: "0.75em",
          },
          a: {
            color: "var(--chakra-colors-blue-500)",
            textDecoration: "underline",
            pointerEvents: "none",
          },
          ul: {
            padding: "0 1rem",
          },
          ol: {
            padding: "0 1rem",
          },
        },
        ".ProseMirror": {
          height: "230px",
          overflowY: "auto",
          overflowWrap: "break-word",
          border: "1px solid var(--chakra-colors-chakra-border-color)",
          padding: "10px",
          width: "100%",
          borderRadius: "var(--chakra-radii-md)",
          transitionProperty: "var(--chakra-transition-property-common)",
          transitionDuration: "var(--chakra-transition-duration-normal)",
          "&:hover": {
            borderColor: "var(--chakra-colors-gray-300)",
          },
          "&:focus": {
            outline: "none",
            borderColor: "var(--chakra-colors-blue-500)",
            boxShadow: "0 0 0 1px var(--chakra-colors-blue-500)",
          },
        },
        "hr.ProseMirror-selectednode": {
          borderTop: "1px solid rgba(0, 0, 0, 0.1)",
        },
      }}
    />
  );
};
