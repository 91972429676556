import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";
import omit from "lodash/omit";

import { Modal, useOpenDialog } from "components/Dialog";

import { FormValues } from "./FormValues";
import { OrganizationForm } from "./OrganizationForm";

const organizationalModalId = "organization-modal";

interface Payload
  extends Omit<
    FormValues,
    "contextManager" | "contextManagerId" | "configuration"
  > {
  type: "company" | "person";
  surname: string | null;
}

export const useOpenOrganizationalModal = () => {
  return useOpenDialog<Payload>(organizationalModalId);
};

export const OrganizationModal = ({ isEdit }: { isEdit?: boolean }) => {
  const isAdding = useIsMutating({
    mutationKey: ["organizations"],
  });

  return (
    <Modal<Payload> id={organizationalModalId}>
      {({ onClose, payload }) => {
        const isCreate = payload === undefined;

        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {isCreate ? t("Dodaj podmiot") : t("Edytuj dane podmiotu")}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <OrganizationForm
                  defaultValues={omit(payload, "type") as FormValues}
                  type={payload?.type}
                  onSuccess={onClose}
                  isEdit={isEdit}
                />
              </ModalBody>
              <ModalFooter>
                <Button mr={3} onClick={onClose}>
                  {t("Anuluj")}
                </Button>
                <Button
                  colorScheme="purple"
                  type="submit"
                  data-testid="add-organization-button"
                  form="organization-form"
                  isLoading={!!isAdding}
                >
                  {isCreate ? t("Dodaj") : t("Edytuj dane")}
                </Button>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};
