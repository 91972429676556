import { ReactNode, useEffect, useRef } from "react";

import {
  AlertDialog as CAlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Center,
  useBoolean,
  AlertProps,
  ButtonProps,
  Stack,
  chakra,
  useBreakpointValue,
} from "@chakra-ui/react";
import { mdiInformationVariant } from "@mdi/js";

import { Icon } from "components/icons/Icon";

import { useDialogPayload } from "./useDialogPayload";
import { useDialogStore } from "./useDialogStore";

interface RenderFooterArgs {
  onClose(): void;
}

interface Props<P> {
  id: string;
  size?: AlertProps["size"];
  title: ReactNode;
  body: ReactNode;
  confirmLabel?: string;
  colorScheme?: ButtonProps["colorScheme"];
  icon?: string;
  isOpen?: boolean;
  isDisabled?: boolean;
  variant?: "simple" | "centered" | "single-action";
  onClose?(): void;
  onConfirm?(payload: P): Promise<unknown> | void;
  renderFooter?: (args: RenderFooterArgs) => ReactNode;
}

export function AlertDialog<P>(props: Props<P>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cancelRef = useRef<any>(null);
  const [isLoading, { toggle }] = useBoolean(false);
  const colorScheme = props.colorScheme ?? "blue";
  const isOpen = useDialogStore(
    (state) => props.isOpen ?? state.isOpen(props.id)
  );
  const onClose = useDialogStore(
    (state) => () => (props.onClose ? props.onClose() : state.onClose(props.id))
  );
  const variant = useBreakpointValue({
    base: "centered",
    md: props.variant ?? "simple",
  });
  const payload = useDialogPayload<P>(props.id);
  const confirmHandler = () => {
    if (!props.onConfirm) {
      return onClose();
    }
    const result = props.onConfirm(payload);
    if (result instanceof Promise) {
      toggle();
      result.finally(() => {
        onClose();
        toggle();
      });
      return;
    }
    onClose();
  };

  useEffect(() => {
    return () => {
      useDialogStore.setState({
        [props.id]: { isOpen: false, payload: undefined },
      });
    };
  }, [props.id]);

  return (
    <CAlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      size={props.size}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <Stack
            direction={variant === "simple" ? "row" : "column"}
            align={variant === "simple" ? "flex-start" : "center"}
            spacing={4}
            px={6}
            py={4}
            pb={0}
          >
            <Center
              flex="none"
              w={variant === "simple" ? "40px" : "48px"}
              h={variant === "simple" ? "40px" : "48px"}
              background={`${colorScheme}.100`}
              borderRadius="100%"
            >
              <Icon
                path={props.icon ?? mdiInformationVariant}
                size="24px"
                color={`${colorScheme}.700`}
              />
            </Center>
            <chakra.div textAlign={variant === "simple" ? "left" : "center"}>
              <AlertDialogHeader
                fontSize="lg"
                fontWeight="bold"
                p={0}
                flexFlow="row"
              >
                {props.title}
              </AlertDialogHeader>
              <AlertDialogBody px={0} fontSize="sm" color="gray.600">
                {props.body}
              </AlertDialogBody>
            </chakra.div>
          </Stack>
          {props.renderFooter ? (
            <AlertDialogFooter>
              {props.renderFooter({ onClose })}
            </AlertDialogFooter>
          ) : (
            <AlertDialogFooter
              flexDirection={{ base: "column-reverse", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
            >
              {props.variant === "single-action" ? (
                <Button
                  id={`${props.id}-cancel-button`}
                  fontSize="sm"
                  ref={cancelRef}
                  flex={{ base: "none", md: 1 }}
                  onClick={confirmHandler}
                  colorScheme={colorScheme}
                  isLoading={isLoading}
                  isDisabled={props.isDisabled}
                  data-testid="confirm-button"
                >
                  {props.confirmLabel ?? "Potwierdzam"}
                </Button>
              ) : (
                <>
                  <Button
                    id={`${props.id}-cancel-button`}
                    fontSize="sm"
                    ref={cancelRef}
                    onClick={onClose}
                    flex={{
                      base: "none",
                      md: variant === "simple" ? "none" : 1,
                    }}
                    mt={{ base: 3, md: 0 }}
                    data-testid="cancel-button"
                  >
                    Anuluj
                  </Button>
                  <Button
                    id={`${props.id}-confirm-button`}
                    fontSize="sm"
                    colorScheme={colorScheme}
                    isLoading={isLoading}
                    isDisabled={props.isDisabled}
                    onClick={confirmHandler}
                    ml={{ base: 0, md: 3 }}
                    flex={{
                      base: "none",
                      md: variant === "simple" ? "none" : 1,
                    }}
                    data-testid="confirm-button"
                  >
                    {props.confirmLabel ?? "Potwierdzam"}
                  </Button>
                </>
              )}
            </AlertDialogFooter>
          )}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </CAlertDialog>
  );
}
