import { QueryClient, useMutation } from "@tanstack/react-query";
import { http, HttpResponse } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { IVerificationProcess } from "../application/types/IVerificationProcess";

const query = (contextId: string) => {
  return {
    queryKey: ["pendingVerifications"],
    queryFn: () =>
      client.get<{ pendingVerifications: IVerificationProcess[] }>(
        `contexts/${contextId}/pendingVerifications`
      ),
  };
};

export const getPendingVerificationsQuery =
  (queryClient: QueryClient) => (contextId: string) => {
    return queryClient.ensureQueryData(query(contextId));
  };

export const usePendingVerificationsQuery = (contextId: string) => {
  return useQuery(query(contextId));
};

export const useGetPendingVerificationsQuery = (contextId: string) => {
  return useMutation({
    mutationKey: ["getPendingVerifications"],
    mutationFn: () =>
      client.get<{ pendingVerifications: IVerificationProcess[] }>(
        `contexts/${contextId}/pendingVerifications`
      ),
  });
};

export const getPendingVerificationsMock = (
  pendingVerifications: IVerificationProcess[] = []
) => {
  return http.get(withHost("contexts/:contextId/pendingVerifications"), () => {
    return HttpResponse.json<{ pendingVerifications: IVerificationProcess[] }>({
      pendingVerifications: [...pendingVerifications],
    });
  });
};
