import {
  FormControl,
  FormLabel,
  Skeleton,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";

import { toastWithError } from "components/ErrorHandling/toastWithError";

import { useAllowUserPasswordChange } from "modules/organization/infrastructure/useAllowUserPasswordChange";

interface IProps {
  organizationId: string;
  isLoading: boolean;
  allowUserPasswordChange: boolean;
}

export const AllowPasswordChange = ({
  organizationId,
  allowUserPasswordChange,
  isLoading,
}: IProps) => {
  const { mutateAsync: toggle } = useAllowUserPasswordChange(organizationId);
  const isMutating = useIsMutating({
    mutationKey: ["allow-user-password-change"],
  });

  if (isLoading) {
    return (
      <VStack align="stretch">
        <Skeleton height="30px" />
        <Skeleton height="80px" />
      </VStack>
    );
  }

  return (
    <VStack align="stretch">
      <FormControl display="flex" alignItems="center" gap="2">
        <Switch
          id="allow-user-password-change"
          name="allow-user-password-change"
          colorScheme="purple"
          isChecked={allowUserPasswordChange}
          isDisabled={!!isMutating}
          onChange={async () => {
            try {
              await toggle({
                isActive: allowUserPasswordChange,
              });
            } catch (e) {
              toastWithError({ error: e });
            }
          }}
        />
        <FormLabel
          htmlFor="allow-user-password-change"
          mb="0"
          fontWeight="bold"
        >
          {t("Zmiana hasła przez użytkowników")}
        </FormLabel>
      </FormControl>
      <Text fontSize="sm" color="gray.600">
        {t(
          "Jeśli włączysz tę funkcję, użytkownicy będą mogli samodzielnie zmieniać swoje hasła bez konieczności kontaktowania się z administratorem. W przypadku wyłączenia tej funkcji, użytkownicy stracą możliwość zmiany hasła, co oznacza, że każda prośba o aktualizację hasła będzie musiała być obsłużona przez administratora."
        )}
      </Text>
    </VStack>
  );
};
