import { useParams, useSearchParams } from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Td,
  Text,
  VStack,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { SearchInput } from "components/Filters/SearchInput";
import { FormatDateTime } from "components/FormatDateTime";
import { Table } from "components/Table";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { TableLoadingState } from "components/Table/TableLoadingState";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";
import { IVerificationHistoryRecord } from "modules/verification/application/types/IVerificationHistoryRecord";
import { useContextVerificationsHistoryQuery } from "modules/verification/infrastructure/useContextVerificationsHistoryQuery";
import { usePendingVerificationsQuery } from "modules/verification/infrastructure/usePendingVerificationsQuery";
import { useVerificationsHistoryQuery } from "modules/verification/infrastructure/useVerificationsHistoryQuery";

import { InterpretationRequestBtn } from "./InterpretationRequestBtn";
import { MarkAllAsReadBtn } from "./MarkAllAsReadBtn";
import { PendingVerificationsTable } from "./PendingVerificationsTable";
import { SignaturesCountBtn } from "./SignaturesCountBtn";
import { VerificationDuration } from "./VerificationDuration";
import { VerificationLink } from "./VerificationLink";
import { VerifiedFilesBtn } from "./VerifiedFilesBtn";
import { VerifiedFilesModal } from "./VerifiedFilesBtn/VerifiedFilesModal";

const columnHelper = createColumnHelper<IVerificationHistoryRecord>();

const columns = [
  columnHelper.accessor(
    (row) =>
      row.type === "PANEL"
        ? `${row.source?.contextName}${row.source.ip}`
        : `${row.type}${row.source.ip}`,
    {
      id: "verification source",
      header: () => t("Źródło weryfikacji"),
      cell: (info) => (
        <Td whiteSpace="normal">
          <VStack align="stretch" spacing={0}>
            <Text>
              {info.row.original.type === "PANEL"
                ? info.row.original.source?.contextName
                : info.row.original.type}
            </Text>
            <Text fontSize="xs" color="gray.600">
              {info.row.original.source.ip}
            </Text>
          </VStack>
        </Td>
      ),
    }
  ),
  columnHelper.accessor("createdAt", {
    header: () => t("Data"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <FormatDateTime date={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("verificationId", {
    header: () => t("Identyfikator weryfikacji"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal" data-testid="verification-link">
        <VerificationLink verificationId={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("timing", {
    id: "verification-duration",
    header: () => t("Czas weryfikacji"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <VerificationDuration
          timing={info.getValue()}
          verificationId={info.row.original.verificationId}
        />
      </Td>
    ),
  }),
  columnHelper.accessor("verificationId", {
    id: "interpretation",
    header: () => "",
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal" isNumeric>
        <HStack>
          <InterpretationRequestBtn verificationId={info.getValue()} />
          {info.row.original.stats.calculated && (
            <VerifiedFilesBtn stats={info.row.original.stats} />
          )}
          {info.row.original.stats.calculated && (
            <SignaturesCountBtn stats={info.row.original.stats} />
          )}
        </HStack>
      </Td>
    ),
  }),
];

interface IProps {
  verifications: IVerificationHistoryRecord[];
  isLoading: boolean;
}

const VerificationsHistoryTableConnected = ({
  verifications,
  isLoading,
}: IProps) => {
  if (isLoading) {
    return <TableLoadingState />;
  }

  if (verifications.length === 0) {
    return (
      <TableEmptyState title={t("Nie wykonano jeszcze żadnych weryfikacji")} />
    );
  }

  const sortedRecords = reverse(
    sortBy(verifications, function (verification) {
      return new Date(verification.createdAt);
    })
  );

  return (
    <Table<IVerificationHistoryRecord> data={sortedRecords} columns={columns} />
  );
};

const ContextVerificationsHistoryTable = ({
  contextId,
}: {
  contextId: string;
}) => {
  const [searchParams] = useSearchParams();
  const { data, isLoading } = useContextVerificationsHistoryQuery(contextId);
  const { data: pendingVerifications, isPending } =
    usePendingVerificationsQuery(contextId);

  return (
    <>
      <VerifiedFilesModal />
      <Card variant="sawpe">
        <Tabs
          isLazy
          variant="enclosed"
          defaultIndex={searchParams.get("unread") ? 1 : 0}
        >
          <CardHeader
            justifyContent="space-between"
            flexWrap={{ base: "wrap", md: "nowrap" }}
          >
            <TabList w="full">
              <Tab>
                <Heading fontSize="lg">{t("Wykonane weryfikacje")}</Heading>
              </Tab>
              <Tab>
                <Heading fontSize="lg">{t("Nieodczytane wyniki")}</Heading>
              </Tab>
            </TabList>
            <Spacer display={{ base: "block", md: "none" }} />
            <SearchInput
              placeholder={t("Źródło, identyfikator lub IP")}
              data-testid="verification-history-search"
            />
          </CardHeader>

          <TabPanels>
            <TabPanel>
              <CardBody>
                <VerificationsHistoryTableConnected
                  verifications={data?.verifications}
                  isLoading={isLoading}
                />
              </CardBody>
            </TabPanel>
            <TabPanel>
              <HStack justifyContent="flex-end" mt={-4}>
                <MarkAllAsReadBtn
                  contextId={contextId}
                  hasPendingVerifications={
                    !!pendingVerifications?.pendingVerifications.length
                  }
                />
              </HStack>
              <CardBody>
                <PendingVerificationsTable
                  verificationProcesses={
                    pendingVerifications?.pendingVerifications
                  }
                  isLoading={isPending}
                />
              </CardBody>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Card>
    </>
  );
};

const OrganizationVerificationsHistoryTable = () => {
  const { organizationId } = useParams<{ organizationId: string }>();

  const { data, isLoading } = useVerificationsHistoryQuery(organizationId!);
  return (
    <>
      <VerifiedFilesModal />
      <Card variant="sawpe">
        <CardHeader>
          <Heading fontSize="lg">{t("Wykonane weryfikacje")}</Heading>
          <Spacer />
          <SearchInput placeholder={t("Źródło, identyfikator lub IP")} />
        </CardHeader>
        <CardBody>
          <VerificationsHistoryTableConnected
            verifications={data?.verifications}
            isLoading={isLoading}
          />
        </CardBody>
      </Card>
    </>
  );
};

export const VerificationsHistoryTable = () => {
  const { userInfo } = ContextMachineContext.useSelector(
    (state) => state.context
  );

  if (userInfo?.currentlySelectedContext?.type === "context_user") {
    return (
      <ContextVerificationsHistoryTable
        contextId={userInfo?.currentlySelectedContext.id}
      />
    );
  }

  return <OrganizationVerificationsHistoryTable />;
};
