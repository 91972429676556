import { Text, Tooltip } from "@chakra-ui/react";
import dayjs from "dayjs";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";
import { ITiming } from "modules/verification/application/types/IVerificationHistoryRecord";

import { VerificationDurationDetailsModal } from "./VerificationDurationDetailsModal";

interface IProps {
  timing: ITiming | null;
  verificationId: string;
}

export const VerificationDuration = ({ timing, verificationId }: IProps) => {
  const context = ContextMachineContext.useSelector((state) => state.context)
    .userInfo?.currentlySelectedContext?.type;

  const availableFor = ["system_admin", "system_manager"];

  const modalId = `verification-duration-details-${verificationId}`;
  const onOpen = useOpenDialog(modalId);

  if (
    !timing ||
    !timing.verificationStartedAt ||
    !timing.verificationFinishedAt
  ) {
    return "---";
  }

  const sentAt = dayjs(timing.sentAt);
  const verificationStartedAt = dayjs(timing.verificationStartedAt);
  const verificationFinishedAt = dayjs(timing.verificationFinishedAt);

  const totalDurationSeconds = verificationFinishedAt.diff(sentAt) / 1000;
  const verificationDurationSeconds =
    verificationFinishedAt.diff(verificationStartedAt) / 1000;

  const formatDuration = (durationInSeconds: number) => {
    if (durationInSeconds < 60) {
      return t("{{duration}} s", { duration: durationInSeconds.toFixed(2) });
    } else {
      const minutes = Math.floor(durationInSeconds / 60);
      const seconds = (durationInSeconds % 60).toFixed(0);
      return t("{{minutes}} min {{seconds}} s", {
        minutes,
        seconds,
      });
    }
  };

  const verificationDuration = formatDuration(verificationDurationSeconds);
  const pendingDuration = formatDuration(
    totalDurationSeconds - verificationDurationSeconds
  );
  const areAdditionalDataAvailable =
    availableFor.includes(context!) && timing.fileStatusDurations.length > 0;

  return (
    <>
      <VerificationDurationDetailsModal
        id={modalId}
        payload={timing.fileStatusDurations}
      />
      <Tooltip
        label={t("Oczekiwanie na weryfikację: {{duration}}", {
          duration: pendingDuration,
        })}
      >
        {areAdditionalDataAvailable ? (
          <Text
            data-testid="verification-duration"
            cursor="pointer"
            onClick={onOpen}
          >
            {!isNaN(+verificationDurationSeconds) && verificationDuration}
          </Text>
        ) : (
          <Text data-testid="verification-duration">
            {!isNaN(+verificationDurationSeconds) && verificationDuration}
          </Text>
        )}
      </Tooltip>
    </>
  );
};
