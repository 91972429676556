import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import {
  Avatar,
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";

import { ChevronRightIcon } from "components/icons/ChevronRightIcon";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

export const OrganizationMenu = () => {
  const navigate = useNavigate();
  const { send } = ContextMachineContext.useActorRef();
  const { userInfo } = ContextMachineContext.useSelector(
    (state) => state.context
  );

  const selectedOrganization = userInfo?.currentlySelectedOrganization;
  const availableOrganizations = userInfo?.availableOrganizations;
  const currentlySelectedContext = userInfo?.currentlySelectedContext;

  if (
    !selectedOrganization ||
    !availableOrganizations ||
    currentlySelectedContext?.type !== "context_manager"
  ) {
    return null;
  }

  return (
    <Menu autoSelect={false}>
      <Tooltip label={t("Przełącz organizację")}>
        <MenuButton
          as={Button}
          title={t("Lista organizacji")}
          data-testid="organization-select-button"
          variant="ghost"
          leftIcon={
            <Avatar
              name={selectedOrganization.details.displayName}
              size="sm"
              bgColor="purple.200"
              color="purple.800"
              mr={{ lg: -4, xl: 0 }}
            />
          }
          rightIcon={
            <ChevronRightIcon
              size="16px"
              color="gray.400"
              transform={"rotate(90deg)"}
              display={{ base: "block", lg: "none", xl: "block" }}
            />
          }
        >
          <Box
            display={{ base: "block", lg: "none", xl: "block" }}
            isTruncated
            maxW="160px"
          >
            {selectedOrganization.details.displayName}
          </Box>
        </MenuButton>
      </Tooltip>
      <MenuList p="2" maxH={"89vh"} overflowY="auto">
        {availableOrganizations.map((availableOrganization) => {
          return (
            <StackedActionItem
              key={availableOrganization.id}
              title={availableOrganization.details.displayName}
              description={
                selectedOrganization?.id === availableOrganization.id
                  ? t("Wybrano")
                  : t("Przełącz")
              }
              isDisabled={selectedOrganization?.id === availableOrganization.id}
              action={() => {
                send({
                  type: "swapOrganization",
                  organizationId: availableOrganization.id,
                });
                navigate(`/${availableOrganization.id}`);
              }}
            />
          );
        })}
      </MenuList>
    </Menu>
  );
};

interface IProps {
  title: string;
  description?: ReactNode;
  isDisabled?: boolean;
  closeOnSelect?: boolean;
  action?: () => void;
}

const StackedActionItem = ({
  title,
  description,
  isDisabled,
  closeOnSelect,
  action,
}: IProps) => {
  const bgColor = useColorModeValue("gray.50", "gray.600");
  const textColor = useColorModeValue("gray.900", "gray.50");
  const secondaryTextColor = useColorModeValue("gray.600", "gray.100");

  return (
    <Box data-group maxW="600px">
      <MenuItem
        rounded="md"
        p="4"
        _groupHover={{
          bgColor,
        }}
        onClick={action}
        isDisabled={isDisabled}
        closeOnSelect={closeOnSelect}
      >
        <HStack spacing="6">
          <Avatar
            name={title}
            size="sm"
            bgColor="purple.200"
            color="purple.800"
          />
          <VStack align="stretch" spacing="0">
            <Text color={textColor} fontWeight="semibold" fontSize="sm">
              {title}
            </Text>
            <Text color={secondaryTextColor} fontSize="sm">
              {description}
            </Text>
          </VStack>
        </HStack>
      </MenuItem>
    </Box>
  );
};
