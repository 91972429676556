import { withTranslation } from "react-i18next";

import { HStack, VStack } from "@chakra-ui/react";

import { OrganizationsTable } from "modules/organization/presentation/OrganizationsTable";
import { InterpretationRequestCountTable } from "modules/verification/presentation/InterpretationRequestCountTable";

export const Organizations = withTranslation()(() => {
  return (
    <VStack align="stretch" gap="8">
      <HStack gap="8" align="start">
        <VStack align="stretch" w="100%" gap="6" p="2px" overflow="auto">
          <OrganizationsTable />
          <InterpretationRequestCountTable />
        </VStack>
      </HStack>
    </VStack>
  );
});
