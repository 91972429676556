import { ReactNode, useState } from "react";

import {
  Button,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { t } from "i18next";

interface IProps {
  children: ReactNode;
  onSave: (url: string) => void;
}

export const InsertLinkPopover = ({ children, onSave }: IProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [link, setLink] = useState("");

  return (
    <Popover
      onOpen={onOpen}
      onClose={() => {
        setLink("");
        onClose();
      }}
      isOpen={isOpen}
      placement="bottom"
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>{t("Wstaw link")}</PopoverHeader>
        <PopoverBody>
          <Input
            size="sm"
            placeholder={t("Adres URL")}
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </PopoverBody>
        <PopoverFooter display="flex" justifyContent="flex-end" gap={2}>
          <Button
            variant="solid"
            onClick={() => {
              setLink("");
              onClose();
            }}
          >
            {t("Anuluj")}
          </Button>
          <Button
            colorScheme="purple"
            variant="solid"
            onClick={() => {
              onSave(link);
              setLink("");
              onClose();
            }}
          >
            {t("Dodaj")}
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
