import { ComponentProps } from "react";

import { mdiFormatListBulleted } from "@mdi/js";

import { Icon } from "./Icon";

export const FormatListBulletedIcon = (
  props: Omit<ComponentProps<typeof Icon>, "path">
) => {
  return <Icon {...props} path={mdiFormatListBulleted} />;
};
