import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  demoAccess: boolean;
  withVisualEffects: boolean;
}

export const useMarkAsDemoAccess = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["allow-user-password-change"],
    mutationFn: (variables: MutationVariables) => {
      if (!variables.demoAccess) {
        return client.put(`organizations/${organizationId}/unmarkAsDemoAccess`);
      }
      return client.put(`organizations/${organizationId}/markAsDemoAccess`, {
        body: {
          withVisualEffects: variables.withVisualEffects,
        },
      });
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "details"],
      });
    },
  });
};
