import { useParams } from "react-router-dom";

import {
  Button,
  chakra,
  HStack,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";

import { Modal } from "components/Dialog";
import { OrganizationMenu } from "components/Layout/OrganizationMenu";
import { CancelIcon } from "components/icons/CancelIcon";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

import { useOrganizationByIdQuery } from "../infrastructure/useOrganizationByIdQuery";

export const DeactivatedOrganizationModal = () => {
  const { userInfo } = ContextMachineContext.useSelector(
    (state) => state.context
  );
  const { send } = ContextMachineContext.useActorRef();

  const { organizationId } = useParams<{ organizationId: string }>();
  const { data } = useOrganizationByIdQuery(organizationId!);

  if (!userInfo) {
    return null;
  }

  // ["system_admin", "system_manager"]
  if (userInfo?.currentlySelectedContext?.type.includes("system")) {
    return null;
  }

  if (
    data?.organization.status !== "BLOCKED" ||
    data?.organization.bannedDate
  ) {
    return null;
  }

  return (
    <Modal id="deactivated-organization-modal" isOpen={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={HStack} color="red">
          <CancelIcon height={"24px"} width={"24px"} />
          <Text>{t("Podmiot został zablokowany")}</Text>
        </ModalHeader>
        <ModalBody px={14} as={VStack} align="stretch">
          <Text>
            {t(
              "Zauważyliśmy działania, które naruszały nasze zasady użytkowania platformy. W związku z tym podmiot "
            )}
            <chakra.span fontWeight="bold">
              {data?.organization.details.displayName}
            </chakra.span>
            {t(" został zablokowany.")}
          </Text>
          <Text>
            {t(
              "Szczegółowe informacje zostały wysłane w wiadomości mailowej. Jeśli uważasz, że doszło do pomyłki, skontaktuj się z nami w celu wyjaśnienia sprawy."
            )}
          </Text>
          <Text>{t("Dziękujemy za zrozumienie.")}</Text>
        </ModalBody>
        <ModalFooter gap={4}>
          {userInfo?.availableOrganizations.length > 1 && <OrganizationMenu />}
          <Button onClick={() => send({ type: "logout" })}>
            {t("Wyloguj")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
