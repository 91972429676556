import { Box, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { HandShakeIcon } from "components/icons/HandShakeIcon";

import { IOrganizationConfiguration } from "modules/organization/application/types/IOrganization";

interface IProps {
  configuration: IOrganizationConfiguration;
}

export const DemoStatus = ({ configuration }: IProps) => {
  if (configuration.demoAccessWithVisualEffects) {
    return (
      <Tooltip label={t("Podmiot demonstracyjny ze znakiem wodnym")}>
        <Box color="purple.500" height="16px" width="16px">
          <HandShakeIcon height="100%" width="100%" />
        </Box>
      </Tooltip>
    );
  }

  if (configuration.demoAccess) {
    return (
      <Tooltip label={t("Podmiot demonstracyjny")}>
        <Box color="yellow.500" height="16px" width="16px">
          <HandShakeIcon height="100%" width="100%" />
        </Box>
      </Tooltip>
    );
  }

  return null;
};
