import { ComponentProps } from "react";

import { mdiFormatListNumbered } from "@mdi/js";

import { Icon } from "./Icon";

export const FormatListNumberedIcon = (
  props: Omit<ComponentProps<typeof Icon>, "path">
) => {
  return <Icon {...props} path={mdiFormatListNumbered} />;
};
