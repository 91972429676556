import { ComponentProps } from "react";

import { mdiFormatBold } from "@mdi/js";

import { Icon } from "./Icon";

export const FormatBoldIcon = (
  props: Omit<ComponentProps<typeof Icon>, "path">
) => {
  return <Icon {...props} path={mdiFormatBold} />;
};
